import { useGet, usePost, usePut } from './axios'

function getGGAuthen() {
  const usedGet = useGet({ url: `${import.meta.env.VITE_APP_STRAPI_API_URL}/init` })

  return usedGet
}

function loginGG(code: string) {
  const token = useCookie('account')
  const usedPost = usePost({
    url: `${import.meta.env.VITE_APP_STRAPI_API_URL}/user-profile`,
    data: {
      code,
    },
  })

  until(usedPost.isFinished).toBeTruthy().then(() => {
    token.value = usedPost.data.value.data.token
  })

  return usedPost
}

function getMe() {
  const token = useCookie('account')
  const usedGet = useGet<IProfile>({
    url: `${import.meta.env.VITE_APP_STRAPI_API_URL}/me`,
    headers: {
      authorization: `Bearer ${token.value}`,
    },
  })

  return usedGet
}

function updateMe(metadata: IProfileMetadata) {
  // Deprecated
  // const { account } = useUserStore()
  const token = useCookie('account')
  const usedPut = usePut<IProfile>({
    // OLD
    // url: `${import.meta.env.VITE_APP_BASE_API_URL}/users/${account?.id}`,
    // NEW
    url: `${import.meta.env.VITE_APP_BASE_API_URL}/users-permissions/users/me`,
    data: {
      metadata,
    },
    headers: {
      authorization: `Bearer ${token.value}`,
    },
  })

  return usedPut
}

export {
  getGGAuthen,
  loginGG,
  getMe,
  updateMe,
}
